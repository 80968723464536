import { useMemo, useEffect, useState } from "react";

import { useParams } from "react-router";
import { isUserExist, showNotification } from "./utils";
import { setUserDataExists } from "./store/wallet/wallet.slice";
import { useDispatch } from "react-redux";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import { useLocation } from "react-router-dom";
// import ContactUs from "./pages/ContactUs";
import { TronLinkAdapter } from "@tronweb3/tronwallet-adapters";
import { WalletConnectAdapter } from "@tronweb3/tronwallet-adapter-walletconnect";
import { contracts } from "./config";
import {
  WalletProvider,
  useWallet,
} from "@tronweb3/tronwallet-adapter-react-hooks";
import {
  WalletActionButton,
  WalletModalProvider,
} from "@tronweb3/tronwallet-adapter-react-ui";
import {
  WalletDisconnectedError,
  WalletError,
  WalletNotFoundError,
} from "@tronweb3/tronwallet-abstract-adapter";

function Minting() {
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [connectedAddress, setConnectedAddress] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [isApproved10LevelRef, setisApproved10LevelRef] = useState(false);
  const [isApproved2LevelRef, setisApproved2LevelRef] = useState(false);

  const [isReferralAllowed, setIsReferralAllowed] = useState(false);
  let { referer } = useParams();
  console.log("Referer: " + referer);
  if (referer == null) {
    referer = "";
  }
  let pricePerToken10Levels = 150;
  let pricePerToken2Levels = 100;
  let nftContract10Levels = "TJUr7sL4kE6bNMM8muFxeiX5wU4jrw1osP";
  let nftContract2Levels = "";
  let usdtContract = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

  const location = useLocation();
  const currentLocation = window.location.host;
  const dispatch = useDispatch();
  const windowInstance = window;
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setTotalCount(inputValue);
  };
  function onError(e) {
    if (e instanceof WalletNotFoundError) {
      showNotification(e.message, "error");
    } else if (e instanceof WalletDisconnectedError) {
      showNotification(e.message, "error");
    } else showNotification(e.message, "error");
  }

  const adapters = useMemo(function () {
    const tronLinkAdapter = new TronLinkAdapter();
    const walletConnectAdapter = new WalletConnectAdapter({
      network: "Mainnet",
      options: {
        relayUrl: "wss://relay.walletconnect.com",
        // example WC app project ID
        projectId: "a18c7e248b10560477a47c190e778e2a",
        metadata: {
          name: "U House Private sale",
          description: "U House Private sale",
          url: "",
          icons: [""],
        },
      },
      web3ModalConfig: {
        themeMode: "dark",
        themeVariables: {
          "--w3m-z-index": "1000",
        },
      },
    });
    // setwalletConnectAdapter(walletConnectAdapter);
    return [tronLinkAdapter, walletConnectAdapter];
  }, []);
  async function haveUserMinted(nftContractAddress, connectedAddress) {
     setIsReferralAllowed(true);
     return;
    try {
      const tron = window.tron;
      const tronWeb = tron?.tronWeb;

      let abi_With_balanceOf = contracts.nftContractABI;

      let contract = await tronWeb.contract(
        abi_With_balanceOf,
        nftContractAddress
      );
      let result = await contract.balanceOf(connectedAddress.toString()).call();
      // console.log("Minted: " + result);
      if (nftContract10Levels == nftContractAddress) {
        console.log("10Levels Minted: " + result);
      } else {
        console.log("2Levels Minted: " + result);
      }
      if (result > 0) {
        setIsReferralAllowed(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // showNotification("Error while fetching nft data", "error");
      console.log(error);
      return false;
    }
  }

  async function mintNft(nftContractAddress, referer) {
    try {
      const tron = window.tron;
      const tronWeb1 = tron?.tronWeb;
      if (connectedAddress == "") {
        showNotification("Please connect wallet first", "error");
        return null;
      }
      if (referer == "") {
        referer = connectedAddress;
      }
      //let amount = totalCount * pricePerToken;
      // const amountInSun = tronWeb1.toSun(amount, 6);

      const parameter = [
        {
          type: "address",
          value: referer,
        },
        {
          type: "uint256",
          value: totalCount,
        },
      ];
      console.log(
        "parameters: " +
          JSON.stringify(parameter) +
          "  / connectedAddress: " +
          connectedAddress
      );

      //  let estimate = await tronWeb1.transactionBuilder.estimateEnergy(
      //    nftContractAddress,
      //    "publicMint(address,uint256)",
      //    { feeLimit: 150000000 },
      //    parameter
      //   );
      //
      //   if (estimate.toString().includes("REVERT")) {
      //     console.log(estimate);
      //     return;
      //   }

      const tx = await tronWeb1.transactionBuilder.triggerSmartContract(
        nftContractAddress,
        "publicMint(address,uint256)",
        { feeLimit: 1000000000 },
        parameter
      );
      console.log(tx);

      const res = await tronWeb1.trx.sign(tx.transaction);
      console.log(tx.transaction);
      // const result = await tronWeb.trx.sendRawTransaction(res);
      const senttx = await tronWeb1.trx.sendRawTransaction(res);
      console.log("senttx: " + senttx);

      if (nftContractAddress == nftContract10Levels) {
        setisApproved10LevelRef(false);
      } else {
        setisApproved2LevelRef(false);
      }
      showNotification(`Nft Minted`, "success");
    } catch (e) {
      console.log(e);
    }
  }
  const approveHandler = async (contractAddress) => {
    try {
      const tron = window.tron;
      const tronWeb1 = tron?.tronWeb;
      if (totalCount <= 0) {
        showNotification("Select Quantity", "error");
        return;
      }
      if (connectedAddress == "") {
        showNotification("Please connect wallet first", "error");
        return null;
      }
      let amount;
      if (contractAddress == nftContract10Levels) {
        amount = totalCount * pricePerToken10Levels;
      } else {
        amount = totalCount * pricePerToken2Levels;
      }

      const amountInSun = tronWeb1?.toSun(amount, 6);
      const parameters = [
        {
          type: "address",
          value: contractAddress,
        },
        {
          type: "uint256",
          value: parseInt(amountInSun),
        },
      ];
      console.log(
        "amountInSun: " + amountInSun + " - nftContract: " + contractAddress
      );
      const tx = await tronWeb1?.transactionBuilder.triggerSmartContract(
        usdtContract,
        "approve(address,uint256)",
        { feeLimit: 1000000000, callValue: 0 },
        parameters,
        tronWeb1.address.toHex(connectedAddress)
      );
      console.log("amountInSun", amountInSun);

      const res = await tronWeb1.trx.sign(tx.transaction);

      const result = await tronWeb1.trx.sendRawTransaction(res);
      if (result.result) {
        // alert("Success");
      }
      if (contractAddress == nftContract10Levels) {
        setisApproved10LevelRef(true);
      } else {
        setisApproved2LevelRef(true);
      }

      showNotification(`Approved ${amount} USDT`, "success");
    } catch (e) {
      console.log(e);
      showNotification("Something went wrong", "error");
    }
  };
  //console.log(address);
  useEffect(() => {
    // if (!address) return;
    // tronWeb.setAddress(address);

    adapters.forEach((adapter) => {
      adapter.on("readyStateChanged", async () => {
        console.log("readyState: ", adapter.address);
        // console.log("Address: " + address);
      });
      adapter.on("connect", async (address) => {
        //const isUserExists = await isUserExist(address?.toLowerCase());
        if (true) {
          setIsWalletConnected(true);
          setConnectedAddress(address);
          try {
            //dispatch(setUserDataExists(true));

            console.log(address);
            await haveUserMinted(nftContract2Levels, address);
            await haveUserMinted(nftContract10Levels, address);
          } catch (error) {
            console.log(error);
          }
        }
      });
      adapter.on("accountsChanged", async (data) => {
        // debugger;
        // dispatch(setUserDataExists(false));
        //const isUserExists = await isUserExist(data?.toLowerCase());
        //  debugger;
        if (true) {
          setIsReferralAllowed(false);
          //dispatch(setUserDataExists(true));
          setIsWalletConnected(true);
          setConnectedAddress(data);
          try {
            console.log(data);
            await haveUserMinted(nftContract2Levels, data);
            await haveUserMinted(nftContract10Levels, data);
          } catch (error) {
            console.log(error);
          }
        }
      });

      adapter.on("disconnect", () => {
        try {
          // dispatch(setUserDataExists(false));
          setIsWalletConnected(false);
          setConnectedAddress("");
          setIsReferralAllowed(false);
        } catch (error) {
          console.log(error);
        }
      });

      return () => {
        adapter.removeAllListeners();
      };
    });

    return () => {
      adapters.forEach((adapter) => {
        adapter.removeAllListeners();
      });
    };
  }, [adapters, dispatch]);

  return (
    <div className="hero-page">
      <WalletProvider
        onError={onError}
        autoConnect={true}
        disableAutoConnectOnLoad={true}
        adapters={adapters}
      >
        <Header />

        <div className="imagem_nft">
          <div className="box2">
            <h1>
              <span className="sec-branco">Mint your Ambition</span>
              <span className="sec-colorido"> NFT</span>
            </h1>
            <div className="sec-pequeno u-meta ">
              <br></br>
              <h1 className="sec-pequeno">Your Referral Link</h1>
              {isReferralAllowed ? (
                <h1 className="sec-pequeno">
                  {currentLocation}/ref/{connectedAddress}
                </h1>
              ) : (
                <h1 className="sec-pequeno">
                 Connect your Wallet
                </h1>
              )}
            </div>
          </div>

          <div className="wrap wrapWidth flex aic jc">
            <div className="_block flex">
              <div className="block-left-side flex">
                <div className="box">
                  {/*<div className="box2">*/}

                  {/*  cuantos foram vendidos
                <div className="numb-sec flex aic jc">
                  <h3 className="numbs">13 / 10,000 NFTs Sold</h3>
                </div>
                */}

                  <div className="slug">
                    <span className="dot"></span>
                    <h2 className="slug-txt">Select your quantity:</h2>
                    <span className="dot"></span>
                  </div>

                  <div className="mint-price-sec flex aic">
                    {/*<div className="eth-val cleanbtn">10 USDT</div>*/}

                    <input
                      type="number"
                      className="e-numb cleanbtn"
                      placeholder="Quantity"
                      value={totalCount}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="butoes_h">
                    <br></br>
                    <span className="bold_butoes">Premium NFT</span>- Price: 150
                    USDT
                  </div>
                  <div className="actions flex flex-col">
                    {isApproved10LevelRef ? (
                      <button
                        className="btn-mint button cleanbtn"
                        onClick={() => {
                          mintNft(nftContract10Levels, referer);
                        }}
                      >
                        Step 2 of 2 → Mint NFT
                      </button>
                    ) : (
                      <button
                        className="btn-mint button cleanbtn"
                        onClick={() => {
                          approveHandler(nftContract10Levels);
                        }}
                      >
                        Step 1 of 2 → Approve Your Wallet
                      </button>
                    )}

                    {/*<div className="butoes_h2">
                <span className="bold_butoes">Standard NFT</span> - Price: 100 USDT 
                </div>

                  {isApproved2LevelRef ? (
                    <button
                      className="btn-mint button cleanbtn"
                      onClick={() => {
                        mintNft(nftContract2Levels, referer);
                      }}
                    >
                      Step 2 of 2 → Mint NFT
                    </button>
                  ) : (
                    <button
                      className="btn-mint button cleanbtn but2"
                      onClick={() => {
                        approveHandler(nftContract2Levels);
                      }}
                    >
                      Step 1 of 2 → Approve Your Wallet
                      
                    </button>
                  )
                  }*/}
                    <a href="https://platfair.ai/tribes/">
                      <button className="but3">
                        Discover our other NFT Tribes{" "}
                      </button>
                    </a>
                  </div>
                </div>
                {/*</div>*/}
              </div>

              <div className="block-right-side flex">
                <p className="texto_tribu">
                  <span className="titulo_texto_tribu">
                    {" "}
                    Tribe of Ambition 🎯
                  </span>
                  <br></br>
                  <br></br>
                  Ascend to heights where dreams touch reality. The Tribe of
                  Ambition lights the path for visionaries, echoing the undying
                  spirit of relentless pursuit.
                  <br></br>
                  <br></br>
                  Chart courses through terrains of aspirations, with each
                  milestone marking unwavering tenacity. From tales of legendary
                  visionaries to modern-day luminaries, this tribe stands as a
                  testament to human ambition.
                  <br></br>
                  <br></br>
                  Embrace the Tribe of Ambition, equipping yourself with the
                  tools to realize your loftiest crypto dreams. This NFT is not
                  just a badge but a commitment to excellence and boundless
                  ambition.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </WalletProvider>
    </div>
  );
}

{
  /* function ConnectWalletComponent() {
  return (
    <WalletActionButton className="btn btn-primary" style={{ width: "100%" }}>
      Connect Wallet
    </WalletActionButton>
  );
} */
}

export default Minting;
